import React from "react"
import parse from "html-react-parser"

export function FaqComponent (category, tagArray, data) {

  let postData = [];
  let jsonData = [];
  if(tagArray.length !== 0){

    tagArray.forEach((array) => {
      // カテゴリ、タグが一致するデータを取得
      let dataArray = data.filter(item => item.node.fxCategories.nodes.some(item2 => item2.slug === category) 
      && item.node.faqtag.nodes.some(item2 => item2.slug === array.slug))[0];
      
      if (dataArray !== undefined) {

        // Q&A生成
        // 自身のリンクを含む場合、そのFAQを生成しない処理追加
        postData.push(<>
          <section className="w-full max-w-4xl">
            <h2 className=""><span className="ez-toc-section">{dataArray.node.title}</span></h2>     
            {dataArray.node.smartCustomFields.map((result) => 
                <>
                  {result.question !== ""? 
                    <div className="is-layout-flow wp-block-group">
                      <pre className="wp-block-preformatted">{result.question}</pre>
                      <pre className="wp-block-preformatted">
                        <p className="!mb-0">{parse(result.answer)}</p>
                        {result.url !== "" || result.linktitle !== ""?
                          <a className="" href={result.url} aria-label="faq" {...result.linktype !== '内部リンク' ? {target: '_blank', rel: 'noopener noreferrer'}: {}}>
                            {result.linktitle}
                          </a>
                        : ''}
                      </pre>
                    </div>
                    :
                    <></>
                  }
                </>
            )}
          </section>
        </>
        )

        // Q&A構造化データ生成
        dataArray.node.smartCustomFields.forEach((result) => {

          if (result.answer.length !== 0) { // 空データのエラー回避処理
            // parse処理
            let answer;
            let answerData = parse(result.answer)[0].props.children;
            (answerData.props?.children === undefined? answer = answerData: answer = answerData.props.children)
            answer = answer[0].length === 1? answer: answer.map((result) =>  result.props? '': result).join('')
            // ※リンク処理いるかも　httpsあるかどうか、絶対パスそのまま、相対パス処理追加？そもそも相対パスやめた方が良いかも
            let jsonItem;
            if (result.linktitle !== ''){
              jsonItem = {
                "@type": "Question",
                "name": result.question,
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": '<p>' + answer + '<a href="' + result.url + '">' + result.linktitle + '</a></p>'
                }
              }
            } else {
              jsonItem = {
                "@type": "Question",
                "name": result.question,
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>" + answer + "</p>"
                }
              }
            }
            jsonData.push(jsonItem)
          }
        })
      }
    })    
  }
  
  return {postData: postData, jsonData: jsonData}
}
