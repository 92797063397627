import parse from "html-react-parser"
const { parseDOM } = require('htmlparser2');
const domSerializer = require('dom-serializer');

export function BlogContentComponent (contentData, linkData) {
  
  // 新設置関数情報取得 
  const varArray = linkData.flatMap((result) =>
    Object.entries(result.node.linksettingdata).flatMap(([groupKey, groupData]) =>
      Object.values(groupData).map((pageData) => ({
        title: result.node.title,
        slug: result.node.slug,
        linktype: groupKey,
        url: pageData.url,
        var: pageData.variables,
      }))
    )
  );
	
  // 旧→新・設置変数変換用配列
  const changevar = [
    {old: 'officialDetails', new: 'toppage/ex'},
    {old: 'accountOpeningPage', new: 'account_opening_page/ex'},
    {old: 'officialCampaignPage', new: 'campaign_page/ex'},
    {old: 'swapPointData', new: 'swap_points_page/ex'},
    {old: 'explanationPage', new: 'basic_information_page/in'},
    {old: 'accountInformationPage', new: 'account_information_page/in'},
    {old: 'accountOpeningProcedure', new: 'setLink/fxgt/account_opening_procedure_page/in'},
  ];

  // 新旧業者名相違リスト
  const changeName = {
    'XMTrading': 'XM Trading', // 旧：新
    'easy Markets': 'easyMarkets',
    'Is6FX': 'IS6FX',
    // 他に相違があれば追加
  };

  const dom = parseDOM(contentData); // HTMLをDOMにパースする
  // すべての要素に対して再帰的に処理を行う関数
  const traverse = (node) => {
    if (node.type === 'tag') { // ノードがタグである場合
      // 処理１：bタグへ変換
      if (node.name === 'strong') {
        node.name = 'b';
      }
      // 処理２：新設置関数ボタン作成
      if (node.attribs && node.attribs.id === 'linkbuttongroup') {
        // 指定IDを持つノード探索１
        const findTextById = (node, id) => {
          if (node.attribs && node.attribs.id === id && node.children && node.children[0]) {
            return node.children[0].data; // 対象IDのノードを出力
          }
          if (node.children) {
            for (let child of node.children) {
              const result = findTextById(child, id);
              if (result) return result;
            }
          }
        };
        // 指定IDを持つノード探索２(子ノードの子ノードのデータ取得)
        const findDeepTextById = (node, id) => {
          if (node.attribs && node.attribs.id === id && node.children && node.children[0]) {
            if (node.children[0].children && node.children[0].children[0]) {
              return node.children[0].children[0].data; // 対象IDのノードの子ノードの子ノードを出力
            } else {
              return node.children[0].data; // 対象IDのノードの子ノードを出力
            }
          }
          if (node.children) {
            for (let child of node.children) {
              const result = findDeepTextById(child, id);
              if (result) return result;
            }
          }
        };
        let addText = node.children.map(child => findTextById(child, 'button-text')).find(Boolean);
        let setText = node.children.map(child => findTextById(child, 'link-text')).find(Boolean);
        let variable = node.children.map(child => findTextById(child, 'variable-text')).find(Boolean);
        let urltext = node.children.map(child => findDeepTextById(child, 'url-text')).find(Boolean);
        let setArray = varArray.filter(item => item.var === variable)[0]; // 設置関数から取得
        let url, linktype;
        if (setArray !== undefined){
          url = setArray.url;
          linktype = setArray.linktype;
        } else {
          url = urltext;
          linktype = 'officialPageGroup'
        }
        // 新ボタン作成
        const noElement = {children: []}; // 空データ 
        const newElement = {
          type: 'tag',name: 'div',
          attribs: {class: 'wp-block-buttons is-layout-flex wp-block-buttons-is-layout-flex',},
          children: [{
            type: 'tag',name: 'div',
            attribs: { class: 'wp-block-button' },
            children: [
              ...(addText !== undefined ? [{ // ボタン上テキストありの場合
                type: 'tag',name: 'div',attribs: { class: 'fukidashi' },
                children: [{
                  type: 'tag',name: 'h5',children: [{ type: 'text', data: addText }] // ①ボタン上テキスト
                }]
              }] : []),
              {
                type: 'tag',name: 'a',
                attribs: {
                  class: 'wp-block-button__link wp-element-button setvar',
                  href: url, // ②ボタンURL
                  'aria-label': 'link',
                  ...linktype === 'officialPageGroup' ? { target: '_blank', rel: 'noopener noreferrer' } : {} // ④ボタンターゲット
                },
                children: [{ type: 'text', data: setText }] // ③ボタン内テキスト
              }
            ]
          }]
        };

        if(url !== undefined){
          Object.assign(node, newElement); // 既存のノードを新しいノードに置き換える
        } else {
          Object.assign(node, noElement); // url情報がない場合は空データに変換
        }
      }
      // 処理３：旧設置関数置換
      if (node.name === 'a' && node.attribs.href && node.attribs.href.includes('makeLink')) {
        let resultArray = node.attribs.href.split('-'); 
        resultArray[1] = changeName[resultArray[1]] || resultArray[1]; // 該当業者姪がある場合変換
        let filteredVarArray = varArray.find(item => item.title === resultArray[1]);
        let filteredChangeVar = changevar.find(item => item.old === resultArray[2]);
        node.attribs.href = varArray.find(item => item.var === `setLink/${filteredVarArray.slug}/${filteredChangeVar.new}`).url;
      }
      
  
      // 子ノードに対して再帰的に処理を行う
      if (node.children && node.children.length > 0) {
        node.children.forEach(traverse);
      }
    }
  };

  dom.forEach(traverse); // DOM全体に対して再帰的に処理を行う
  const blogContents = parse(domSerializer.default(dom)); // 変更されたDOMをHTMLにシリアライズ
  
  return blogContents
}