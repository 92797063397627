import React from "react";
import { Link as Scroll } from 'react-scroll';


export function BlogTableContentsComponent (contentData) {
	
	// 目次生成処理
	let count = 1;
  let check = 0;
  let firstItem, secondItem, thirdItem, endItem, addNum, tableHtml, hrefData, titleData;
  let setContentArray = [];
  while (check !== -1) {
    firstItem = 'ez-toc-heading-' + String(count);
    check = contentData.indexOf(firstItem)
    if( check !== -1){
      // ID：ez-toc-headingの全体を取得
      secondItem = '</a></li>';
      endItem = '"';
      addNum = '';
      tableHtml = makeTabContens(contentData, firstItem, secondItem, addNum)
      // hrefを取得
      thirdItem = 'href=';
      addNum = thirdItem.length + 2
      hrefData = makeTabContens(tableHtml, thirdItem, endItem, addNum);
      // titleを取得
      thirdItem = 'title=';
      addNum = thirdItem.length + 1
      titleData = makeTabContens(tableHtml, thirdItem, endItem, addNum);
      titleData = titleData.replace('&nbsp;', '')
      setContentArray.push([hrefData,titleData])
      count++;
    }
  };
  let tableContens;
  if ( setContentArray.length !== 0 ) { // 目次があるとき
    // 目次（PC）
    tableContens = setContentArray.map((result) => {
      return  <dd className="cursor-pointer"><Scroll to={result[0]} smooth={true} offset={0}><span className="hover:text-gray-400">{result[1]}</span></Scroll></dd>
    });
  } else { // 目次がないとき
    tableContens = "notable";
  }
  // html取得用関数
  function makeTabContens( data, beforeItem, afterItem, addNum){
    let index  = data.indexOf(beforeItem) + addNum;
    let string = data.substring(index);
    index  = string.indexOf(afterItem);
    string = string.substring(0, index);
    return string
  };

  return tableContens
}